export const stableCoinsETH = {
    "0xdac17f958d2ee523a2206206994597c13d831ec7": "USDT",
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": "USDC",
    "0x6b175474e89094c44da98b954eedeac495271d0f": "DAI",
    "0x0000000000085d4780B73119b644AE5ecd22b376": "TUSD",
    "0x4fabb145d64652a948d72533023f6e7a623c7c53": "BUSD",
    "0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6": "USDD",
    "0x8e870d67f660d95d5be530380d0ec0bd388289e1": "USDP",
    "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd": "GUSD",
    "0xa47c8bf37f92abed4a126bda807a7b7498661acd": "USTC"
}

export const stableCoinsBNB = {
    "0x55d398326f99059ff775485246999027b3197955": "USDT",
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": "USDC",
    "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3": "DAI",
    "0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9": "TUSD",
    "0xe9e7cea3dedca5984780bafc599bd69add087d56": "BUSD",
    "0xd17479997f34dd9156deef8f95a52d81d265be9c": "USDD",
    "0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40": "FRAX",
    "0xc5f0f7b66764f6ec8c8dff7ba683102295e16409": "FDUSD",
    "0x14016e85a25aeb13065688cafb43044c2ef86784": "Bridged TUSD"
}

export const stableCoinsTRX = {
    "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t": "USDT",
    "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8": "USDC",
    "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4": "TUSD",
    "TMz2SWatiAtZVVcH2ebpsbVtYwUPT9EdjH": "BUSD",
    "TPYmHEhy5n8TCEfYGqW2rPxsghSfzghPDn": "USDD",
    "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT": "USDJ",
}
